
#mahmed{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(-90deg, rgb(62, 60, 147) 0%, rgb(106, 139, 188) 53%, rgb(142, 187, 218) 100%);
   margin: 0;
padding: 0;
  min-height: 100vh;
  height: auto;
}
#ahmed_row{
    border-radius:20px;
    box-shadow: 0 2px 25px rgba(252, 245, 245, 0.2);
    background-color: #fff;
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#card_a21{
  width: 100%;
  box-shadow: 0 2px 25px rgba(210, 202, 202, 0.2);
  min-height: 300px;
}
#ahm_col1{
  direction: rtl;
  float: right;
  text-align: right;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  
}

#ahm_col2{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
}

#field1_ah{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}
#field1_ahname{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
#width_ah{
  width: 70%;
  height: 40px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid #005D7F;
  outline: none;

}
#forGGetContiner{
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  color: #005D7F;

}
#forGGetContiner a{
  cursor: pointer;
  text-decoration: none;
}
#log_ah{
  background-color:#ffa450;
  width: 262px;
  height: 34px;
  border: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 0px 0px 5px 1px #aaa;
  -moz-box-shadow: 0px 0px 5px 1px #aaa;
  box-shadow: 0px 0px 5px 1px #aaa;
  cursor: pointer;
}
#log_ah:hover{
  transform: scale(1.05);
  transition-duration: .3s;
  transition-timing-function: linear;
  
}
#log_ah:active {
  transform: scale(1);
  transition-duration: .1s;
  transition-timing-function: linear;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#log1_ah{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 30px */

}
#logoLoginIMG{
  width: 100%;
  border-radius: 20px 0px 0px 20px;
}
@media (max-width: 1050px) { 
  #logoLoginIMG{
    margin: 5%;
    width:90%;
    border-radius: 20px 20px 20px 20px;
  }
}
 @media (max-width: 575.98px) { 
#ahm_col2{
  display: none;
}
#log_ah{
width: 50%;
}

 }

 @media (min-width: 576px) and (max-width: 767.98px) { 
  #ahm_col2{
    display: none;
  }
 }