@font-face {
  font-family: "Kareem";
  src: url(./assets/Hacen-Tunisia-Lt.ttf);
}

* {
  font-family: "Kareem";
}

#InputTExtDash22 {
  width: 68%;
  height: 34px;
  border: none;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;

}

#ColCate {
  margin: 25px 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

}

#ColCate1 {
  margin: 25px 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

}

.DataTableContiner {
  direction: ltr;
}

#CardCategory {
  width: 200px;
  height: 240px;
  background-color: #fafafa;
  border-radius: 10px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  cursor: pointer;
}

#CardCategory:hover,
#acoountBtN:hover {
  transform: scale(1.04);
  transition-duration: .2s;
  transition-timing-function: linear;
}

#CardCategory:active,
#acoountBtN:active {
  transform: scale(1);
  transition-duration: .2s;
  transition-timing-function: linear;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#msgDelete {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
}

#inputAddContiner {
  /* border: 1px solid #4a7fc1; */
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding: 20px 20px;
}

#ContinerInPut {
  direction: rtl;
  display: flex;
  justify-content: flex-end;
  margin: 20px 0px;

}

#ContinerInPut label {
  width: 33%;
  text-align: right;

}

#InputTExtDash1 {
  width: 70%;
  height: 40px;
  border: 0px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding-right: 20px;
  background-color: #fafafa;

}
#LLLB{
  height:15px;
  width:400px;
  background-color :#000!important
}
#InputTExtDash260 {
  width: 260px;
  height: 40px;
  border: 0px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding-right: 20px;
  background-color: #fafafa;

}

#InputTExtDash2 {
  width: 68%;
  height: 32px;
  /* border: #4a7fc1 solid 1px; */
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;

}

#InputTExtDash260file {
  width: 260px;
  height: 32px;
  /* border: #4a7fc1 solid 1px; */
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;

}

#imgCategory {
  width: 100%;
  height: 200px;
  border-radius: 10px 10px 0px 0px;
}

#nameCategory {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background-color: #fbc85c;
  border-radius: 0px 0px 10px 10px;
  font-size: 17px;
  font-weight: 600;
  color: #fff;
}

#COLHomE {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px 0px;
}

.ActiveSliderBTN div {
  border-bottom: solid #ffa450 6px;
  border-top: solid #ffa450 6px;
  border-left: 0px;
  border-right: solid #fff 6px;
  color: #ffffff;
  background: #343a40;
  font-weight: 500;
}

#NavContinerApp {
  position: absolute;
  left: 0px;

}
.classesroot {
  direction: rtl;
  background-color: #C1A269;
}
.wating {
  background-color: #F8C471;
  color: #fff;
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.done {
  background-color: #76D7C4;
  color: #fff;
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cancel {
  background-color: #dd6656;
  color: #fff;
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wating1 {
  background-color: #F8C471;
  color: #fff;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.done1 {
  background-color: #76D7C4;
  color: #fff;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cancel1 {
  background-color: #dd6656;
  color: #fff;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#tablePrintMargin {
  margin-bottom: 100px;
}
#RootNAmeprint {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 100%;
  border: #000 solid 2px;
  border-bottom-width: 0px;
  border-top-width:  8px;
  font-size: 18px;
  color: #000;
  font-weight: 500;
  /* direction: rtl; */
  text-align: right;
  padding: 5px;
}
#SectionTable {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
  border: #000 solid 2px;
  border-bottom-width: 0px;
  font-size: 22px;
  color: #000;
  font-weight: bold;
  direction: rtl;
  text-align: center;
  padding: 5px;
}
#students {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border: 2px solid #000;
  width: 100%;
  direction: rtl;
  font-size: 22px;
  color: #000;
  font-weight: bold;
}

#students td, #students th {
  border: 2px solid #000;
  padding: 2px;
}

#students tr:nth-child(even) {
  background-color: #f2f2f2;
}

#students tr:hover {
  background-color: #ddd;
}

#students th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #ffa726;
  color: black;
}

#notsAllOrder {
  display: flex;
  align-items: center;
  min-height: 60px;
  max-height: auto;
  width: 100%;
  border: #000 solid 2px;
  /* border-top-width: 0px; */
  font-size: 22px;
  color: #000;
  font-weight: 500;
  direction: rtl;
  text-align: right;
  padding: 5px;
}
#roundedIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  background-color: #938888;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
#studentsRoot {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border: 2px solid rgb(110, 107, 107);
  width: 100%;
  direction: rtl;
  font-size: 22px;
  color: #000;
  font-weight: bold;
}

#studentsRoot td, #studentsRoot th {
  border: 2px solid rgb(110, 107, 107);
  padding: 2px;
}

#AppbarContiner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#AppbarContiner a:hover,
#ColCate a,
#ColCate1 a {
  text-decoration: none;
}

#sliderCateImgContiner1 a {
  color: #000;
}

#sliderCateImgContiner1 a:hover {
  text-decoration: none;
}

#titleAddAppBar {
  width: 100;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fbc85c;
  border-radius: 5px;
  margin-bottom: 50px;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

#btnAddItemContiner {
  width: 100%;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#btnAddItem {
  width: 200px;
  height: 40px;
  background-color: #fbc85c;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

#officeBannerBtn {
  width: 80px;
  height: 25px;
  background-color: #fff;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffa450;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

}

#btnAddOffice {
  width: 200px;
  height: 40px;
  background-color: #ffa450;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

#btnAddItem:hover,
#btnAddOffice:hover,
#officeBannerBtn:hover,
#StorBtn:hover,
#iconSochil:hover {
  transform: scale(1.04);
  transition-duration: .2s;
  transition-timing-function: linear;
}

#btnAddItem:active,
#btnAddOffice:active,
#officeBannerBtn:active,
#StorBtn:active,
#iconSochil:active {
  transform: scale(1);
  transition-duration: .2s;
  transition-timing-function: linear;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#CardCategory a:hover {
  text-decoration: none;
}

.mapDescPage {
  height: 300px;
  width: 100%;
}

.mapAddOffice {
  height: 55vh;
  width: 100%;
}

#continerAddOffice {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

#Addoffice {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px 0;

}

#colbanner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px 0;
}

#inputAddContinerOffice {
  min-height: 55vh;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding: 20px 20px;
  display: flex;
  align-items: center;
}

#BannerContinerDash {
  width: 300px;
  height: 100px;
  border-radius: 5px;
  background-color: #eee;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

#BannerContiner {
  width: 100%;
  height: 25vw;
  border-radius: 5px;
  background-color: #eee;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

#imgBannerDash {
  width: 300px;
  height: 100px;
}

#textAreaAdd {
  width: 70%;
  border: 0px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding-right: 20px;
  background-color: #fafafa;

}

#ContinerLogoSlider {
  padding: 30px 0;
  height: 300px;
  background-color: #ECF0F1;
  /* direction: rtl; */
  text-align: right;
}

#arrowasliderContiner1 {
  position: absolute;
  z-index: 11;
  left: 0px;
  height: 35px;
  width: 30px;
  background-color: #ffa450;
  opacity: 0.6;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

#arrowasliderContiner {
  position: absolute;
  z-index: 11;
  right: 0px;
  height: 35px;
  width: 30px;
  background-color: #ffa450;
  opacity: 0.6;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

#sliderCateImgContiner {
  width: 220px;
  height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin: 10px 0;
  background-color: #fff;
}

#underLinkCate {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 180px;
  border-radius: 10px;
}

#sliderCateImgContiner a,
#sliderCateImgContiner a:hover {
  color: #ffa450;
  text-decoration: none;
}

#TItIMG {
  width: 100%;
  height: 200px;
  display: flex;
  direction: rtl;
  background-color: #fff;
}

#TItIMG1 {
  direction: ltr;
  width: 200px;
  height: 200px;
  position: relative;
}

#IM11 {
  position: absolute;
  z-index: 2;
  width: 200px;
  height: 200px;
}

#IM22 {
  position: absolute;
  z-index: 3;
  width: 0;
  height: 0;
  border-top: 100px solid transparent;
  border-bottom: 100px solid transparent;

  border-left: 100px solid #fff;
}

#TItIMG2 {

  display: flex;
  align-items: center;
  justify-content: left;
  margin-right: 10px;
  color: #ffa450;
}

#sliderCateImgContiner1 {
  width: 160px;
  height: 180px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin: 10px 0;
  background-color: #fff;
  cursor: pointer;
  position: relative;
}

#labeladdOffice {
  width: 120px;
  display: flex;
  justify-content: flex-start;
}

#InputTExtDash260Select {
  width: 260px;
  height: 38px;
  border: 0px;
  box-shadow: 0px 2px 4px -1px rgba(199, 184, 184, 0.2), 0px 4px 5px 0px rgba(199, 184, 184, 0.2), 0px 1px 10px 0px rgba(199, 184, 184, 0.2);
  border-radius: 5px;
  background-color: #fafafa;

}

#ImgBestOffers {
  height: 120px;
  width: 160px;
  border-radius: 10px 10px 0px 0px;
}

#discountOnCard {
  height: 20px;
  width: 50px;
  position: absolute;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 10px;
  top: 20px;
  color: #ffa450;
  font-weight: 500;
}

#scatehome {
  height: 200px;
  background-color: #ffa450;
}
.INPUtCOUnt{
  border: 0px;
  outline: none;
  width: 50px;
  height: 35px;
}

#deleteBannerDash {
  position: absolute;
  bottom: 10px;
  right: calc(50% - 140px);
  color: #ffa450;
  background-color: #fff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  cursor: pointer;
}

#footerContiner {
  margin-top: 30px;
  min-height: 200px;
  width: 100%;
  background-color: #fff;
}

#footerRedContiner {
  min-height: 150px;
  width: 100%;
  background-color: #ffa450;
}

#ColFooter1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
  padding: 0px 20px;
}

#ColFooter2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#ColFooter3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#storContiner {
  width: 100%;
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

#StorBtn {
  height: 40px;
  width: 120px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  cursor: pointer;
  margin: 10px;
}

#iconSochil {
  height: 40px;
  width: 40px;
  cursor: pointer;
  margin: 10px;
  color: #fff;
}

#continerItemCate {
  margin-top: 100px;
  min-height: calc(100vh - 400px);
  width: 100%;
}

#headerDescItemContiner {
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  margin: 30px 0px;
}

#headerDescItemContent1 {
  min-width: 250px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;

}

#headerDescItemContent1 h4 {
  color: #ffa450;
}

#headerDescItemContent2 {
  min-width: 250px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 25px;
  background-color: #D8DCDD;


}

#shadowROOw {
  width: 95%;
  margin: 0px 2.5%;
  direction: rtl;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  padding: 0px;
  border-radius: 15px;
}

#SHOPCONTINER {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
#SIGNOUTBTN{
  margin-top: 25px;
  width: 80%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0px 30px;
  background-color: rgb(226, 85, 85);
  color: #fff;
   border-radius: 8px ;

}
#PLUSMIN {
  height: 40px;
  width: 300px;
  border: 1px solid #ffa450;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content:center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline-style: none; /* Internet Explorer  */
}

#SHOpBTNCON ,#SHOpBTNCON1{
  display: flex;
  align-items: center;
  justify-content:space-between;
  height: 40px;
  width: 300px;
  background-color: #ffa450;
  color: #fff;
  border-radius: 10px;
  padding: 0px 30px;
  cursor: pointer;
}
#SHOpBTNCON:hover,#SHOpBTNCON1:active{
  transform: scale(1.03)
}
#SHOpBTNCON:active ,#SHOpBTNCON1:active{
  transform: scale(1);
  -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline-style: none; /* Internet Explorer  */
}
#ColitemDDess1 {
  margin: 0px;
  padding: 0px;
  width: 100%;
  min-height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#ColitemDDess2 {
  background-color: #fff;
  margin: 0px;
  padding: 20px;
  width: 100%;
  min-height: 350px;
  border-radius: 15px 0px 0px 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
}

#mgeitemOnly {
  width: auto;
  height: 340px;
  border-radius: 15px;
}

#priceDisee {
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: row-reverse;

}

#headerDescItemContinerLeft {
  display: flex;
  align-items: center;
}

#logoOfficeContiner {
  margin-left: 30px;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

}

#userImageAccount {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #eee;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

#contentAcccountContiner {
  margin-top: 25px;
  display: flex;
  width: 80%;
}

#inputAccountShow {
  direction: ltr;
  width: 100%;
  height: 35px;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eee;
}

#inputAccountShowFile {
  direction: ltr;
  width: 260px;
  height: 34px;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  outline: none;
  border: none;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #eee;
}

#inputAccountShowed {
  width: 260px;
  height: 40px;
  padding-right: 20px;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  outline: none;
  border: none;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#acoountBtN {
  margin-right: 50px;
  width: 260px;
  height: 40px;
  margin-top: 40px;
  cursor: pointer;
  background-color: #ffa450;
  color: #fff;
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

#navbarBtnContiner a {
  width: 130px;
  color: #fff;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
#PRiCountCON{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  color: #fff;
  background-color: #ffa450;
  height: 35px;
  width: 100%;
}
#PRiCountCON1{
  margin-top: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: #fff;
  background-color: #ff5650;
  height: 35px;
  width: 100%;
  border-radius: 10px;
}
#PRiCountCON1:hover{
  transform: scale(1.02);
  transition-duration: .2s;
  transition-timing-function: linear;
}
#PRiCountCON1:active{
  transform: scale(1);
  transition-duration: .2s;
  transition-timing-function: linear;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#LineS{
height: 3px;
width: 96%;
margin: 1.5%;
background-color: #ffa450;
}
#DOTSR{
  display: none;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 15px;
  height: 20px;
  width: 20px;
  background-color: red;
  border-radius: 50%;
  color: #fff;
}
#DOTSR1{
  display: none;
  position: absolute;
  top: -5px;
  right: -5px;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  background-color: red;
  border-radius: 50%;
  color: #fff;
}
#navbarBtnContiner div {
  width: 130px;
  color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
}

#navbarBtnContiner a:hover {
  text-decoration: none;
}

#navbarCContiner {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#MenueeBTn {
  display: none;
}

#navContentLeftmenuTrue {
  display: none;
}

#navContentLeft {
  display: flex;
}

#moreHomeContiner {
  direction: rtl;
  display: flex;
  height: 40px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
}

#moreHomeContiner a,
#moreHomeContiner a:hover {
  color: #ffa450;
  text-decoration: none;
}

#userImageAccount1 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

@media (max-width: 992px) {
  #mgeitemOnly {
    width: 100%;
    height: auto;
    border-radius: 15px 15px 0px 0px;
  }

  #ColitemDDess2 {
    border-radius: 0px 0px 15px 15px;
  }

  #ColFooter3 {
    justify-content: center;
  }

  #storContiner {
    justify-content: center;
  }

  #hideenlogo {
    display: none;
  }

}

@media (max-width: 768px) {
  #navContentLeft {
    display: none;
  }

  #navContentLeftmenuTrue {
    display: flex;
  }

  #MenueeBTn {
    display: flex;
  }
}